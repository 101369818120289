.scores {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 1280px) {
    .scores {
        max-width: 1000px;
    }
}

@media (max-width: 1000px) {
    .scores {
        max-width: 768px;
    }
}

.scores__title {
    font-size: 4rem;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 800;
    color: white;
    text-align: center;
    margin: 10%;
}

@media (max-width: 768px){
    .scores__title {
        font-size: 10vw;
    }
}

@media (min-width: 1280px){
    .scores__title {
        font-size: 6.25rem;
    }
}

.scores__scoresFlexBox {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.scores__animeWell {
    background-color: #333;
    border-radius: 10px;
    padding: 5px;
    margin: 10px;
}

.scores__animeBlock {
    margin: 10px;
}

.scores__anime {
    color: white;
    font-size: 1.1rem;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 800;
}

.scores__songFlexBox {
    display: flex;
}

.scores__song {
    display: table-cell;
    vertical-align: middle;
    color: inherit;
    margin: 10px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    width: 100%;
}

.scores__songText {
    font-size: 1rem;
    text-align: center;
    margin: 12px;
}

.scores__songWell {
    display: table;
    padding: 10px;
    border: 1px solid #999;
    border-radius: 10px;
    margin: 10px;
    width: 100%;
    text-decoration: none;
    color: white;

}

.scores__songWell:hover {
    background-color: white;
    color: #333;
    transition: all 0.3s linear;
}

@media (max-width: 768px) {
    .scores__songFlexBox {
        flex-direction: column;
    }

    .scores__songWell {
        width: auto;
    }
}