.title {
    margin-top: 10%;
    margin-bottom: 15%;
}

.title__titleen {
    font-size: 4rem;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 800;
    color: white;
    text-align: center;
}

.title__subtitleen {
    text-align: center;
    font-family: "Source Sans Pro", sans-serif;
    color: white;
    font-weight: 600;
    font-size: 4vw;
}

.title__titlejp {
    font-size: 8vw;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 800;
    color: white;
    text-align: center;
}

.title__subtitlejp {
    text-align: center;
    font-family: 'Noto Sans JP', sans-serif;
    color: white;
    font-weight: 600;
    font-size: 4vw;
}

@media (max-width: 768px){
    .title__titleen {
        font-size: 10vw;
    }

    .title__titlejp {
        font-size: 10vw;
    }

    .title__subtitleen {
        font-size: 6vw;
    }
    .title__subtitlejp {
        font-size: 6vw;
    }
}

@media (min-width: 1280px){
    .title__titleen {
        font-size: 6.25rem;
    }
    .title__titlejp {
        font-size: 6.25rem;
    }

    .title__subtitleen {
        font-size: 2.5rem;
    }
    .title__subtitlejp {
        font-size: 3.5rem;
    }
}