.header, .header__background, .header__backgroundAnimation {
    height: 61px;
    position: sticky;
    z-index: 100;
}

@media (max-width: 768px) {
    .header {
        top: 0;
        background-color: #333;
    }
}

.header__background {
    background-color: #333;
    top: 0;
}

@media (min-width: 768px) {
    .header__background {
        animation: growDown 400ms ease-in-out;
        transform-origin: top center;
    }
}

@keyframes growDown {
    0% {
        transform: scaleY(0)
    }
    80% {
        transform: scaleY(1.1)
    }
    100% {
        transform: scaleY(1)
    }
}

.header__container {
    height: 61px;
    display: flex;
    align-items: center;
    top: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
}

@media (max-width: 1280px) {
    .header__container {
        max-width: 1000px;
    }
}

@media (max-width: 1000px) {
    .header__container {
        max-width: 768px;
    }
}

/* Navigation */
.header__nav {
    display: flex;
    justify-content: space-evenly;
}

.header__link {
    text-decoration: none;
}

.header__option, .header__optionActive, .header__optionDonate {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 10px;
    margin-left: 5px;
}

@media (min-width: 768px) {
    .header__invisibleOnDesktop
    {
        display: none;
    }
}

.header__optionDonate {
    background-color: #FF4533;
    padding-left: 30px;
    padding-right: 30px;
    margin-right: 10px;
}

@media (max-width: 768px) {
    .header__optionDonate {
        display: none;
    }
}

.header__optionActive {
    background-color: #04aa6d;
}

.header__option:hover, .header__optionDonate:hover{
    background-color: #111;
}

.header__optionTexten {
    font-size: 1.125rem;
    font-family: "Anton", sans-serif;
    color: white;
    text-transform: uppercase;
}

.header__optionTextjp {
    font-size: 1.5rem;
    font-family: 'Kosugi Maru', sans-serif;
    color: white;
    text-transform: uppercase;
}

.header__center {
    margin: 0 auto;
}

@media (max-width: 768px) {
    .header__center {
        margin-left: auto;
        margin-right: 10px;
    }
}

@media (max-width: 768px) {
    /* Navigation */
    .header__nav, .header__navOnClick {
        z-index: -1;
        transform: translateX(100%);
        justify-content:flex-start;
        flex-flow: column nowrap;
        background-color: #333;
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        width: 150px;
        padding-top: 61px;
        transition: transform 0.3s ease-in-out;
    }

    .header__navOnClick {
        transform: translateX(0);
    }

    .header__option {
        padding: 10px;
    }
}

/* Search Bar */
.header__search {
    display: flex;
    flex: 1;
    align-items: center;
    border-radius: 24px;
    margin-left: 10px;
    margin-right: 10px;
}

.header__searchInput {
    height: 12px;
    padding: 10px;
    border: none;
    width: 100%;
}

.header__searchIcon {
    padding: 5px;
    height: 22px !important;
    background-color: white;
}

/* Hamburger */
.header__hamburger, .header__hamburgerOnClick {
    /* margin: 10px; */
    width: 30px;
    height: 40px;
    justify-content: space-evenly;
    flex-flow: column nowrap;
    z-index: 20;
    display: none;
}

@media (max-width: 768px) {
    .header__hamburger, .header__hamburgerOnClick {
        display: flex;
    }
}

.header__hamburger > div, .header__hamburgerOnClick > div {
    /* width: 30px; */
    height: 5px;
    background-color: white;
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
}

.header__hamburgerOnClick > div {
    background-color: lightgray;
}

.header__hamburgerOnClick > div:nth-child(1) {
    transform: rotate(45deg);
    width: 33px;
}

.header__hamburgerOnClick > div:nth-child(2) {
    transform: translateX(100);
    opacity: 0;
}

.header__hamburgerOnClick > div:nth-child(3) {
    transform: rotate(-45deg);
    width: 33px;
}

/* logo */
.header__logo {
    width: 100px;
    margin-left: 10px;
}